.root {
  margin: -16px -16px 0 -16px;
  padding: 0 16px 48px 16px;
  background-color: #ddefff;
}
.dynamixText {
  font-size: rem(40px);
  line-height: 1.2;
  @media (min-width: $mantine-breakpoint-sm) {
    font-size: rem(48px);
  };
  @media (min-width: $mantine-breakpoint-md) {
    font-size: rem(68px);
  }
}
.dynamicImage {
  position: relative;
  width: rem(400px);
  height: rem(300px);
  @media (min-width: $mantine-breakpoint-sm) {
    width: rem(500px);
    height: rem(400px);
  }
}
.leftSection {
  @media (min-width: $mantine-breakpoint-xs) {
    flex-shrink: 0;
    width: 40%;
  }
}
.description {
  font-size: rem(18px);
  @media (min-width: $mantine-breakpoint-lg) {
    font-size: rem(20px);
  }
}
